section {
  max-width: 1024px;
  margin: 32px auto;
  padding: 0 32px;
  width: 100%;
  box-sizing: border-box;
  &.error {
    padding-top: 4rem; }
  h2 {
    font-size: 26px;
    color: #252525;
    margin-top: 1em; }
  h3 {
    font-size: 18px;
    color: #252525;
    margin-top: 1em; }
  p {
    font-size: 16px;
    line-height: 1.6;
    font-weight: 400;
    margin-top: 1em; }
  li {
    font-size: 16px;
    line-height: 1.6;
    font-weight: 400; }
  .centered {
    text-align: center; }
  ul:not(.cards) {
    margin: 16px 0 16px 32px; }
  ul.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: stretch;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-items: center;
      width: 256px;
      margin: 0 16px;
      text-decoration: none;
      border-radius: 2px;
      h2 {
        font-weight: 300;
        font-size: 22px;
        margin-top: 32px; }
      p {
        font-weight: 300;
        font-size: 16px;
        margin-bottom: 32px;
        text-align: center; }
      .img {
        flex-grow: 1;
        display: flex;
        align-items: end;
        img {
          max-height: 256px;
          max-width: 256px; } }
      a {
        display: inline-block;
        position: relative;
        border: 0 none;
        white-space: nowrap;
        text-align: center;
        text-decoration: none;
        outline: 0 none;
        border-radius: 3px;
        cursor: pointer;
        padding: 8px 16px;
        font-weight: bold;
        line-height: 20px;
        font-size: 14px;
        user-select: none;
        min-width: 88px;
        transition: background-size 500ms cubic-bezier(0.98, 0.005, 0.79, 1.005), background-color 500ms cubic-bezier(0.98, 0.005, 0.79, 1.005), color 500ms cubic-bezier(0.98, 0.005, 0.79, 1.005), box-shadow 300ms;
        background: radial-gradient(circle, rgba(127, 127, 127, 0.1) 10%, transparent 10.001%, transparent) no-repeat center center;
        background-size: 0;
        color: #0075BF;
        margin-top: 16px;
        &:hover, &:focus, &:active {
          background-color: rgba(127, 127, 127, 0.1);
          background-size: 1200%; } } } } }
