nav {
  position: sticky;
  top: 0;
  background: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 0 16px;
  z-index: 100;

  ul {
    display: flex;
    align-items: center;
    flex-direction: row;
    max-width: 1024px;
    margin: 0 auto;

    li {
      display: block;
      line-height: 56px;
      margin: 0 8px;
      &.active a {
        color: #282828;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          height: 2px;
          background: #0075BF; } }
      a {
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 56px;
        color: #757575;
        text-decoration: none;
        position: relative;
        padding: 8px 8px 0 8px;
        font-size: 16px;
        line-height: 1.25;
        text-align: center;
        &:hover, &:active, &:focus {
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            height: 2px;
            background: #757575; } } }
      &.spacer {
        flex-grow: 1; }
      &.title a {
        padding: 4px 8px;
        h1 {
          display: inline-block;
          img {
            width: 32px;
            height: 32px;
            vertical-align: bottom; }
          span {
            color: #0075BF;
            font-weight: bold;
            font-size: 24px;
            letter-spacing: -1px;
            line-height: 24px;
            margin-left: 4px;
            @media (max-width: 480px) {
              display: none; } } } } } } }
