* {
  margin: 0;
  padding: 0; }

*:focus {
  outline: none; }

*::-moz-focus-inner {
  border: 0; }

body {
  background: #fafafa;
  font-family: "Lato", sans-serif;
  font-size: 81.25%; }

a {
  color: #0075BF;
  &:not(:hover):not(:focus):not(:active) {
    text-decoration: none; } }
