header {
  background: #0075BF;
  display: block;
  padding: 32px 0;
  h2 {
    font-size: 26px;
    color: #ffffff;
    text-align: center; }
  p {
    color: #f0f0f0; } }
